import {useState, useEffect} from 'react';
import { TypeAnimation } from 'react-type-animation';

import Draggable from 'react-draggable'
import Typewriter from 'typewriter-effect';
import { NavLink } from 'react-router-dom';
function GridPopupLogo(props){
    const [isVisible, setVisible] = useState(true);
    useEffect(() => {
        setTimeout(() => setVisible(false), 2000)
    }, []);
    return(
        <>
            <div className={isVisible? "grid_popup_logo" : "grid_popup_logo hidden"}>
                <div className='inner'>
                <div className='small_logo'>Metaverse Landscapes:</div>
                <div className='big_logo'>Patchwork</div>
                <div className='small_logo'>***Digital World***</div>
                </div>
            </div>
        </>
    )
}
export default GridPopupLogo;