import {useState, useRef, useEffect, Fragment} from 'react'
import axios from "axios";
import { ENSName } from 'react-ens-name';
import useMediaQuery from '@mui/material/useMediaQuery';
import TextWithLinks from './Hooks/TextWithLinks';
function Chat(props) {
    const [messages, setMessages] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [inputError, setInputError] = useState(null);
    const [time, setTime] = useState(Date.now());
    const [welcome, setWelcome] = useState(false);
    const chatEnd = useRef(null);
    const [chatbotMessages, setchatbotMessages] = useState([]);
    const [chatHistory, setchatHistory] = useState([]);
    const [booleanArray, setBooleanArray] = useState(new Array(50).fill(false));
    const [isChattingWithBot, setChattingWithBot] = useState(false);
    const [chatCollapsed, setChatCollapsed] = useState(false);
    const isMobile = useMediaQuery('(max-width:700px)');

    const scrollToBottom = () => {
        chatEnd.current?.scrollIntoView({ behavior: "smooth" });
    }
    const toggleValue = (index) => {
        setBooleanArray((prevArray) => {
          const newArray = [...prevArray]; // Creating a copy of the array
          newArray[index] = !newArray[index]; // Toggling the value at the specified index
          console.log(newArray)
          return newArray;
          
        });
      };
    useEffect(() => {
        scrollToBottom();
    }, [messages, chatHistory, props.activePlan?.id]);
    const API_KEY = "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";
    const fetchData = async (input_prompt, isWelcome) => {
        var old_array = chatbotMessages;
        const selectedChat = chatHistory.filter(obj => obj.activePlane == props.activePlane?.id)
        console.log(chatHistory, selectedChat)
        var new_array = old_array.concat([{"content": input_prompt, "role": "user"}]);
        const response = await axios.post(
          "https://metaverselandscapespatchwork.com:8000/predict", {'question':input_prompt, 'history':selectedChat},
          {
            headers: {
              "Content-Type": "application/json"
            },
          }
        );
  //      console.log(response.data)
        var oldchat = chatHistory;
        var newchat = oldchat.concat([{'q':input_prompt, 'a':response.data, 'activePlane':props.activePlane?.id}]);
        setchatHistory(newchat);
        //setMessages(messages => [...messages, {"message": response.data, "expires": 30, "type": "bot"}]);
        setchatbotMessages(new_array.concat([{"content": response.data, "role": "assistant"}]))
        props.socket.emit('message', {
            text: response.data,
            type: `bot`,
            isWelcome: isWelcome,
            socketID: 'bot',
            activePlane: props.activePlane?.id
        });
        console.log(messages)
        return response.data;
    };
    const sendBorderWarning = () => {
        const msg = "Error! You are not allowed to cross borders without a passport. Buy one here."
        var old_array = chatbotMessages;
        var new_array = old_array.concat([{"content": msg, "role": "user"}]);
        var oldchat = chatHistory;
        var newchat = oldchat.concat([{'q':'Tell me I cant cross the border', 'a':msg}]);
        setchatHistory(newchat);
        setchatbotMessages(new_array.concat([{"content": msg, "role": "assistant"}]))

    }
    if(welcome === false) {
        setWelcome(true);
        //fetchData("Wish me welcome to the Metaverse Landscape "+props.activePlane.id+" parcel and the platform")
    }
useEffect(() => {
    if(props.activePlane?.id) {
        if(booleanArray[props.activePlane?.id] == false) {
    //        console.log('will welcome')
            toggleValue(props.activePlane?.id);
            fetchData("Wish me welcome to the Metaverse Landscape "+props.TokenInfo[props.activePlane?.id]?.column_3+" parcel and tell me about it. Include the name of the token.", true)
            console.log(props.TokenInfo[props.activePlane?.id]?.column_3)
        } else {
    //        console.log('will not welcome ', props.activePlane?.id)
        }
    }
}, [props.activePlane?.id])
    useEffect(() => {
        props.socket.on('messageResponse', (data) => {
            if(data.isWelcome == true && booleanArray[props.activePlane?.id] == true) {
            } else {
            setMessages([...messages, {"message": data.text, ethAdr: data.ethAdr, "type":data.type, "activePlane":data.activePlane, 'isWelcome':data.isWelcome}])
            }
    });
       console.log(messages)
    }, [props.socket, messages]);
    function handleInputChange(event) {
        const value = event.target.value;
        setInputValue(value);

        if (value.length < 0) {
        setInputError('Input must be at least 1 characters');
        } else {
        setInputError(null);
        }
    }

    function handleSubmit(event) {
        event.preventDefault();
        if (inputValue.length > 0) {
            //setMessages(messages => [...messages, {"message": inputValue, expires: 30}]);
            if(isChattingWithBot) {
                fetchData(inputValue, false);
            }
            props.socket.emit('message', {
                text: inputValue,
                id: `${props.socket.id}${Math.random()}`,
                ethAdr: props.ethAdr,
                socketID: props.socket.id,
                activePlane: props.activePlane?.id
            });
            props.setLatestUserMessage(inputValue)
            setInputValue('');
        } else {
            setInputError('Input must be at least 5 characters');
        }
    }
    return(
        <Fragment>
        <div className={"chat"}>
            <div className={"inner"}>
                <div className={"input_field"}>
                   
                    <form onSubmit={handleSubmit}>
                    <input type="text" placeholder={props.isEN ? "Type here" : "輸入"} value={inputValue} onChange={handleInputChange}></input>
                    {isMobile && <div class="break"></div>}
                    <div className={isChattingWithBot ? "bot_checkbox checked" : "bot_checkbox"} onClick={() => setChattingWithBot(!isChattingWithBot)}>
                        <div className='checkbox'>
                            <div className='inner'></div>
                        </div>
                        <div className='label'>{props.isEN ? "Chat to Bot" : "與機器人聊天"}</div>
                    </div>
                    
                    <input type="submit" value={props.isEN ? 'Send' : '送出'} />
                    </form>
                </div>
            </div>
        </div>
        {messages.filter(obj => obj.activePlane == props.activePlane?.id).length > 0 && 
        <div className={chatCollapsed ? "chat_box collapsed" : "chat_box"}>
            <div className='chat_collapse' onClick={() => setChatCollapsed(!chatCollapsed)}><div className='collapse_button'>{chatCollapsed ? "+" : "-"}</div> Chat</div>
            <div className="scrollbox">
        {messages.filter(obj => obj.activePlane == props.activePlane?.id).map(function(m, i){
            return(
            <div className={"item"}>
                <div className={"adr"}>
                    {m.type == "bot" ?
                     "MetaverseLandscapeX_NPC_Resident"
                    :
                    m.ethAdr != "" ?
                    <ENSName address={m.ethAdr}></ENSName>
                    :
                    "GUEST"
                    }
                </div>
                <div className={"text"}>
                    <div className={"name"}>
                    <TextWithLinks text={m.message} />
                    </div>
                </div>
            </div>
            )
        })}
        <div style={{ float:"left", clear: "both" }}
                        ref={chatEnd}>
                    </div>
        </div>
        </div>
        }
        </Fragment>
    )
}
export default Chat;