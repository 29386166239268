import React from 'react';

const TextWithLinks = ({ text }) => {
  if (typeof text !== 'string') {
    console.error('TextWithLinks component expects a string as the text prop.');
    return null; // or return a default component state
  }

  const urlRegex = /(\bhttps?:\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])|(\bwww\.[-A-Z0-9+&@#\/%=~_|!:,.;]*\b)/gim;

  // Split text by URLs
  const parts = text.split(urlRegex);

  return (
    <>
      {parts.map((part, index) => {
        if (part && part.match(urlRegex)) { // Check if part is not undefined and is a URL
          const href = part.startsWith('http') ? part : `http://${part}`;
          return (
            <a key={index} href={href} target="_blank" rel="noopener noreferrer">
              {part}
            </a>
          );
        } else {
          // Ensure part is not undefined before rendering
          return part ? <span key={index}>{part}</span> : null;
        }
      })}
    </>
  );
};

export default TextWithLinks;
