import './App.css';
import {useThree} from "react-three-fiber";
import { Canvas, useFrame, useLoader, extend} from '@react-three/fiber'
import { useProgress, Preload } from '@react-three/drei'
import { PerspectiveCamera, RoundedBox, Environment, Text } from '@react-three/drei';
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import * as THREE from 'three';
import { useState, useEffect, useRef, forwardRef } from 'react';
import { Fragment, Suspense } from "react";
import useKeyPress from "./Hooks/useKeyPress.js";
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import FadingText from './FadingText.js';
import Etherscan from './Icons/Etherscan.svg';
import OS from './Icons/OS.svg';
import socketIO from 'socket.io-client';
import table from './data.json';
import tokeninfo from './Token_Info.json';
import Globe from './BotTextures/Globe.png';
import { useSpring, a } from "@react-spring/three"
import passportColors from "./PassportColors.json"
import PixeloidMono_Regular from './Fonts/PixeloidMono.woff'
import OldRegular from './Fonts/OldEnglishGothicPixelRegular-gx1jp.woff'
import MintPage from './MintPage.js';
import { isAddress } from 'web3-validator';
import { og_owners, ml_owners } from './TokenOwners.js'
import Chat from './Chat.js'
import { useSwipeable } from 'react-swipeable';
import { useCombinedRefs } from './Hooks/useCombinedRefs.js'
import { Loader } from './Hooks/Loader.js';
import useMediaQuery from '@mui/material/useMediaQuery';

//extend({ Text });
const TokenInfo = tokeninfo.tokens;
const socket = socketIO.connect(':4000');
//const botimg = require('./nvidia.JPG');
//const botmodel = require('./bot5.glb');
const images = require.context('./Imgs/hires', true);
//const imagesLowres = require.context('./Imgs/lowres', true);
const imageList = images.keys().map(image => images(image)).sort((a, b) => a - b);
//const imageListLowRes = imagesLowres.keys().map(image => imagesLowres(image)).sort((a, b) => a - b);

const outerBoundingBox = new THREE.Box3();
outerBoundingBox.min = new THREE.Vector3(-5 + 0.05, -5 + 0.15, 7);
outerBoundingBox.max = new THREE.Vector3(105 - 0.4, 35 - 0.15, 7);

const Player = forwardRef(function Player(props, ref) {
    /*const LeftPressed = useKeyPress('ArrowLeft');
    const RightPressed = useKeyPress('ArrowRight');
    const UpPressed = useKeyPress('ArrowUp');
    const DownPressed = useKeyPress('ArrowDown');
    const speed = 0.1;*/
    const navigate = useNavigate();
    //const [activePlane, setActivePlane] = useState(null);
    const [array, setArray] = useState([]);
    const [onBorderCollision, setBorderCollision] = useState(false);
    const [restrictedMode, setRestrictedMode] = useState(true);
    const [isOnBorder, setOnBorder] = useState(false);
    const boundingBoxRef = useRef()
    const boundingBoxSize = { x: 10, y: 10, z: 2 }
    const { size, viewport } = useThree()
    const aspect = size.width / viewport.width
    const [spring, set] = useSpring(() => ({ scale: [1, 1, 1], position: [0, 0, 0.8], rotation: [0, 0, 0], config: { friction: 20 } }))
    /*const bind = useDrag((
        { offset: [x, y] }) => set({ position: [x / aspect, y / aspect, 0.8]}), 
        {
    
    })*/
    const texture = props.tex;
    useEffect(() => {
        setArray(props.array);
        setRestrictedMode(props.restrictedMode)
    //   console.log(props.array)
    }, [props.array, props.restrictedMode]);
    useEffect(() => {
        
    }, [])
    //                            props.setBorderAlert(true)
    function clamp(value, min, max) {
        if(value > max - 0.5 || value < min + 0.5) {
            return Math.min(Math.max(value, min), max);
        } else {
            return Math.min(Math.max(value, min), max);
        }
    }
    function clampBool(value, min, max) {
        if(value > max - 0.5 || value < min + 0.5) {
            return true;
        } else {
            return false;
        }
    }
    useEffect(() => {
        document.body.classList.add("map");
        return () => {
            document.body.classList.remove("map");
        }
    }, []);
    useEffect(() => {
        if(!props.restrictedMode) {
            props.setBorderAlert(false)
        }
    }, [props.restrictedMode])
    //isOnActivePlane function
    useEffect(() => {
        if(props.shown){
            ref.current.position.set(props.playerPosX.n, props.playerPosY.n, 0.8)
            setTimeout(() => {props.setShown(false)}, 2000);
        }
    }, [props.playerPosX.n, props.playerPosY.n]);
    useFrame(({ clock }) => {
        const playerPos = ref.current.position;
        //ref.current.position.x = props.playerPosX;
        //ref.current.position.y = props.playerPosY;
        if(!props.shown) {
            ref.current?.position.lerp(new THREE.Vector3(props.playerPosX.n, props.playerPosY.n, playerPos.z), 0.1);
        } else {
            //ref.current?.position.lerp(new THREE.Vector3(props.playerPosX.n, props.playerPosY.n, playerPos.z), 1);
        }
                //console.log('active ', props.activePlane)
                if(props.activePlane && props.zoomedToItem){
                    boundingBoxRef.current.position.x = props.activePlane.x;
                    boundingBoxRef.current.position.y = props.activePlane.y;
                    //console.log('is restricted,' , props.restrictedMode)
                    if(props.restrictedMode){
                        //console.log('this user is restricted')
                        playerPos.x = clamp(playerPos.x, boundingBoxRef.current.position?.x - 5, boundingBoxRef.current.position?.x + 5 - .3);
                        playerPos.y = clamp(playerPos.y, boundingBoxRef.current.position?.y - 5 + .15, boundingBoxRef.current.position?.y + 5 - .15);
                        let xBool = clampBool(playerPos.x, boundingBoxRef.current.position?.x - 5, boundingBoxRef.current.position?.x + 5 - .3);
                        let yBool = clampBool(playerPos.y, boundingBoxRef.current.position?.y - 5 + .15, boundingBoxRef.current.position?.y + 5 - .15);
                        if(xBool || yBool) {
                            if(!isOnBorder && !props.shown) {
                                setOnBorder(true)
                                props.setBorderAlert(true)
                            }
                        } else {
                            setOnBorder(false)
                        }
                    } else{
                        playerPos.x = clamp(playerPos.x, outerBoundingBox.min.x, outerBoundingBox.max.x);
                        playerPos.y = clamp(playerPos.y, outerBoundingBox.min.y, outerBoundingBox.max.y);
                    }
                }
    })
    return(
        <>
        <a.mesh position={[0, 0, 0.8]}ref={ref} castShadow>
            <RoundedBox args={[0.3, 0.3, 0.3]} radius={0.03}>
                <meshStandardMaterial metalness={0} roughness={0.1} color={props.color} receiveShadow />
            </RoundedBox>
            {props.hasPassport &&
            <mesh rotation={[0, 0, 0]} position={[0, 0, 0.16]}>
            <planeGeometry args={[0.2, 0.2]} />
            <meshBasicMaterial
                map={texture}
                transparent
                toneMapped={false}
            />
            </mesh>
            }
            {!props.hasPassport &&
                <text
                position={[0, 0, 0.16]}
                font={PixeloidMono_Regular}
                rotation={[0, 0, 0]}
                fontSize={0.07}
                text={'GUEST'}
                color={'#fff'}
                anchorX="center"
                anchorY="middle"
                >
                    <meshStandardMaterial toneMapped={false} emissive={"white"} attach="material" color={"white"} />
                </text>
            }
            <FadingText
            text1={props.latestUserMessage}
            position={[0, 0.3, 0]}
            duration={5000}
            />
        </a.mesh>
        <mesh ref={boundingBoxRef} visible={false}>
            <boxGeometry attach="geometry" args={[boundingBoxSize.x, boundingBoxSize.y, boundingBoxSize.z]} />
            <meshBasicMaterial attach="material" wireframe />
        </mesh>
        </>
    )
});
const GhostPlayer = forwardRef(function GhostPlayer(props) {
    const speed = 0.1;
    const ref = useRef(null);
    const navigate = useNavigate();
    const [activePlane, setActivePlane] = useState(null);
    const [array, setArray] = useState([]);
   const texture = props.tex;
    useEffect(() => {
        setArray(props.array);
    }, [props.array]);
    useFrame(({ clock }) => {
        ref.current?.position.lerp(new THREE.Vector3(props.pos[0], props.pos[1], ref.current.position.z), 0.1);
    })    
    return(
        <mesh position={[0, 0, 0.8]} ref={ref} castShadow>
            <RoundedBox args={[0.3, 0.3, 0.3]} radius={0.03}>
                <meshStandardMaterial metalness={0} roughness={0.1} color={props.color} receiveShadow />
            </RoundedBox>
            {isAddress(props.ethAdr) &&
            <mesh rotation={[0, 0, 0]} position={[0, 0, 0.16]}>
            <planeGeometry args={[0.2, 0.2]} />
            <meshBasicMaterial
                map={texture}
                transparent
                toneMapped={false}
            />
            </mesh>
            }
            {!isAddress(props.ethAdr) &&
                <text
                position={[0, 0, 0.16]}
                font={PixeloidMono_Regular}
                rotation={[0, 0, 0]}
                fontSize={0.07}
                text={'GUEST'}
                color={'#fff'}
                anchorX="center"
                anchorY="middle"
                >
                    <meshStandardMaterial toneMapped={false} emissive={"white"} attach="material" color={"white"} />
                </text>
            }
            <FadingText
            text1={props.msg}
            position={[0, 0.3, 0]}
            duration={5000}
            />
        </mesh>
    )
});
const DummyPlayer = forwardRef(function DummyPlayer(props, ref) {
    const { viewport } = useThree();
    const speed = 0.1;
    const time = 5;
    const [activePlane, setActivePlane] = useState(null);
    const [array, setArray] = useState([]);
    const [vec, setVec] = useState(null);
    return(
        <mesh position={props.pos} scale={[1,1,1]} rotation={[Math.PI/2, 0, 0]} ref={ref} castShadow>
            <RoundedBox args={[0.3, 0.3, 0.3]} radius={0.03}>
                <meshStandardMaterial metalness={0} attach="material" roughness={0.1} color={passportColors?.passportColors[props.coordid].main} receiveShadow />
            </RoundedBox>
        <Text
        position={[0, 0.18, -0.02]}
        font={OldRegular}
        rotation={[-Math.PI/2, 0, 0]}
        ref={ref}
        color={'#fff'}
        fontSize={0.04}
        maxWidth={200}
        lineHeight={1}
        letterSpacing={0.02}
        textAlign={'center'}
        anchorX="center"
        anchorY="middle"
      >
        <meshStandardMaterial toneMapped={false} emissive={"white"} attach="material" color={"white"} />
        ML{String(Number(props.coordid+6))}
      </Text>
        <Text
        position={[0, 0.18, 0.05]}
        rotation={[-Math.PI/2, 0, 0]}
        ref={ref}
        color={'#fff'}
        fontSize={0.04}
        maxWidth={200}
        lineHeight={1}
        letterSpacing={0.02}
        textAlign={'center'}
        font={PixeloidMono_Regular}
        anchorX="center"
        anchorY="middle"
      >
        <meshStandardMaterial toneMapped={false} emissive={"white"} attach="material" color={"white"} />
        HOST BOT
      </Text>
        </mesh>
    )
});
const BoundedCamera = ({ children }) => {
    const outerBoundingBox = new THREE.Box3();
    outerBoundingBox.min = new THREE.Vector3(-5, -5, 7);
    outerBoundingBox.max = new THREE.Vector3(105, 35, 7);
    const camera = useFrame(({ camera }) => {
      // Clamp the camera position to the bounding box.
      camera.position.clamp(outerBoundingBox.min, outerBoundingBox.max);
  
      return children;
    });
  
    return camera;
};

const CanvasElements = forwardRef((props, ref) => {
    const [innerRef, setRefs] = useCombinedRefs();
    const [zoomToItem, setZoom] = useState(props.zoomToItem);
    const [hasZoomed, setHasZoomed] = useState(false)

    //const ref = useRef(null);
    const cameraRef = useRef();
    const [users, setUsers] = useState([])
    const activesocketID = socket.id;
    const GlobeTexture = useLoader(TextureLoader, Globe);
    GlobeTexture.wrapS = THREE.RepeatWrapping;
    GlobeTexture.wrapU = THREE.RepeatWrapping;
    useEffect(() => {
        socket.on('newCoordResponse', data => setUsers(data))
    }, [socket])
    const activePlayerColor = users.findIndex((obj => obj.userId == activesocketID));
    const lookAtVec = new THREE.Vector3(0, 0, 0);
    const cameraVector = new THREE.Vector3(0, 0, 0);
    useEffect(() => {
        //setCameraPosition(props.activePlane.x, props.activePlane.y, 0);
        //state.camera.updateProjectionMatrix();
        //state.camera.updateMatrix();
        //state.camera.updateMatrixWorld();
    }, [])
    useFrame((state) => {
            const boxPos = ref.current.position;
            //console.log('current ref pos '+boxPos.x)
            lookAtVec.set(boxPos.x, boxPos.y, boxPos.z);
            state.camera.position.lerp(new THREE.Vector3(lookAtVec.x - 2.15, lookAtVec.y - 0.1, lookAtVec.z + 7), hasZoomed ? 0.5 : 1);
            state.camera.updateProjectionMatrix();
            state.camera.updateMatrix();
            state.camera.updateMatrixWorld();
            var frustum = new THREE.Frustum();
    });
    useFrame((state) => {
        setTimeout(()=> {
            setHasZoomed(true)
        }, 1000)
    }, [])
    return(
        <>
                <ambientLight intensity={[1]}/>
                <mesh rotation={[0, 0, 0]}>
                {table.table.map((item, index) => (
                    <MapItem tex={GlobeTexture} playerRef={ref} item={item} i={index} coord={props.array[index]} activePlane={props.activePlane} />
                ))}
                <Player setShown={props.setShown} shown={props.shown} zoomedToItem={props.zoomedToItem} playerPosX={props.playerPosX} playerPosY={props.playerPosY} tex={GlobeTexture} passPortId={props.passPortId} hasPassport={props.hasPassport} latestUserMessage={props.latestUserMessage} setBorderAlert={props.setBorderAlert} ref={ref} array={props.array} color={users[activePlayerColor] ? users[activePlayerColor].color : '#000000'} restrictedMode={props.restrictedMode} activePlane={props.activePlane} />
                <UserHandler tex={GlobeTexture} latestUserMessage={props.latestUserMessage} />
                </mesh> 
                <PerspectiveCamera ref={cameraRef} fov={[30]} makeDefault position={[0, 0, 3]} rotation={[-0, -0.3, 0]}
  frustumCulling
  frustum={outerBoundingBox} />
        </>
    )
});
function clamp2(value, min, max) {
    if(value > max - 0.1 || value < min + 0.1) {
    }
    return Math.min(Math.max(value, min), max);
}
function MapItem(props) {
    const isMobile = useMediaQuery('(max-width:700px)');
    const [itemCoord, setCoord] = useState(null);
    const [isActive, setActive] = useState(false);
    const planeRef = useRef(null);
    const ref2 = useRef();
    let path = isMobile ? './Imgs/lowresnew/' : './Imgs/hires/'
    const texture = useLoader(TextureLoader, require('./Imgs/hires/'+props.item.imgFile))
    //let activeTexture = texture;
    texture.encoding = THREE.sRGBEncoding;
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapU = THREE.RepeatWrapping;
    const boundingBoxRef = useRef()
    const boundingBoxSize = { x: 9, y: 9, z: 2 }

    useEffect(() => {
        if(props.coord?.id == props.activePlane?.id) {
            setActive(true)
        } else{
            setActive(false)
        }
        if(isActive) {
        //let texture = useLoader(TextureLoader, require('./Imgs/lowres/'+props.item.imgFile))
        }
    }, [props.activePlane])
    useFrame((clock) => {
        if(planeRef.current) {
            boundingBoxRef.current.position.x = planeRef?.current.position.x;
            boundingBoxRef.current.position.y = planeRef?.current.position.y;
            let playerPos = props.playerRef?.current.position;
            ref2.current.position.x = clamp2(ref2.current.position.x, boundingBoxRef.current.position?.x - 5, boundingBoxRef.current.position?.x + 5 - .3);
            ref2.current.position.y = clamp2(ref2.current.position.y, boundingBoxRef.current.position?.y - 5, boundingBoxRef.current.position?.y + 5 - .3);
        if(isActive) {
            ref2.current?.position.lerp(new THREE.Vector3(playerPos.x + 0.5, playerPos.y +0.8, playerPos.z), 0.07);
        }
    
        }
    })
    useFrame((state) => {

    })
    useEffect(() => {
        //console.log(props.coord)
        setCoord(props.coord);
    }, [props.coord]);
    return(
        <>
            {itemCoord && 
            <>
            
                    <mesh position={[itemCoord.x, itemCoord.y, 0]} ref={planeRef} receiveShadow>
                                <boxGeometry args={[10, 10, 0.2]}  />
                                <meshStandardMaterial attach="material" map={texture} />

                    </mesh>
                        
            <DummyPlayer coordid={props.coord.id} ref={ref2} pos={[itemCoord.x, itemCoord.y, 0.8]} id={props.i} />
            <mesh ref={boundingBoxRef} visible={isActive ? false : false}>
            <boxGeometry attach="geometry" args={[boundingBoxSize.x, boundingBoxSize.y, boundingBoxSize.z]} />
            <meshBasicMaterial attach="material" wireframe />
            </mesh>
            </>
            }
        </>

    )
}
function UserHandler(props) {
    const [users, setUsers] = useState([])
    const activesocketID = socket.id;
    useEffect(() => {
        socket.on('newCoordResponse', data => setUsers(data))
        //console.log(users)
    }, socket)
    return(
        <Fragment>
        {users && users.filter(user => user.userId !== activesocketID).map((user) =>
            <GhostPlayer tex={props.tex} ethAdr={user.ethAdr} color={user.color} pos={[user.posX,user.posY]} msg={user.msg} />
        )}
        </Fragment>
    )
}

const Map = (props) => {
    const zoomToItem = props.zoomToItem;
    const [activePlane, setActivePlane] = useState(null);
    const [restrictedMode, setRestrictedMode] = useState(false);
    const [borderAlert, setBorderAlert] = useState(false);
    const [detailIsHidden, setDetailIsHidden] = useState(true);
    const [latestUserMessage, setLatestUserMessage] = useState("");
    const [mintPage, setMintPage] = useState(false)
    const { active, progress, errors, item, loaded, total } = useProgress();
    const [array, setArray] = useState([]);
    const [zoomedToItem, setZoomed] = useState(false);
    let { id } = useParams();
    const location = useLocation();
    //Player
    const playerRef = useRef(null);
    const [playerPosX, setPlayerPosX] = useState({n: 0, init: 0})
    const [playerPosY, setPlayerPosY] = useState({n: 0, init: 0})
    const LeftPressed = useKeyPress('ArrowLeft');
    const RightPressed = useKeyPress('ArrowRight');
    const UpPressed = useKeyPress('ArrowUp');
    const DownPressed = useKeyPress('ArrowDown');
    const speed = 0.5;
    useEffect(() => {
        const moveInterval = 5; // Adjust speed by changing the interval
        let intervalId;
        //console.log(restrictedMode, 'restricted')
        const moveCube = () => {
            if(LeftPressed){
                setPlayerPosX({n: playerRef.current.position.x - speed, t: 'animated'})
                //playerRef.current.position.x = playerRef.current.position.x - speed;
                //set({ position: [ref.current.position.x - speed, ref.current.position.y, 0.8]})
            }
            if(RightPressed){
                setPlayerPosX({n: playerRef.current.position.x + speed, t: 'animated'})
                //playerRef.current.position.x = playerRef.current.position.x + speed;
                //set({ position: [ref.current.position.x + speed, ref.current.position.y, 0.8]})
            }
            if(UpPressed){
                setPlayerPosY({n: playerRef.current.position.y + speed, t: 'animated'})
                //playerRef.current.position.y = playerRef.current.position.y + speed;
                //set({ position: [ref.current.position.x, ref.current.position.y + speed, 0.8]})

            }
            if(DownPressed){
                setPlayerPosY({n: playerRef.current.position.y - speed, t: 'animated'})
                //playerRef.current.position.y = playerRef.current.position.y - speed;
                //set({ position: [ref.current.position.x, ref.current.position.y - speed, 0.8]})
            }
            if(LeftPressed || RightPressed || UpPressed || DownPressed){
                socket.emit('newCoord', {posX: playerRef.current.position.x, posY: playerRef.current.position.y, id: `${socket.id}`,socketID: socket.id,msg: props.latestUserMessage});
            }
        };
        intervalId = setInterval(moveCube, moveInterval); // 60 FPS
        return () => clearInterval(intervalId);
    }, [LeftPressed, RightPressed, UpPressed, DownPressed])
    const swipe = useSwipeable({
        onSwiped: (e) => {
            //console.log("User Swiped!", e)
            if(e.dir == "Up") {
                setPlayerPosY({n:playerRef.current.position.y - e.absY / 50})
            }
            if(e.dir == "Down") {
                setPlayerPosY({n: playerRef.current.position.y + e.absY / 50})
            }
            if(e.dir == "Left") {
                setPlayerPosX({n: playerRef.current.position.x + e.absX / 50})
            }
            if(e.dir == "Right") {
                setPlayerPosX({n: playerRef.current.position.x - e.absX / 50})
            }
        },
        trackMouse: true
    });
    useEffect(() => {
        //console.log(ref.current.position)
        if(!restrictedMode) {
        if(playerRef?.current) {
            var x = playerRef?.current.position.x;
            var y = playerRef?.current.position.y;
            var z = playerRef?.current.position.z;
            var plane = array.find(function(plane){
                return plane.x > x - 5 && plane.x < x + 5 && plane.y > y - 5 && plane.y < y + 5;
            });
            setActivePlane(plane);
            //props.setActivePlaneParent(plane)
            //console.log(plane)
        }
        }
    });
    useEffect(() => {
        //console.log('zoomed to item', zoomedToItem)
        if(zoomedToItem){
        let basePath = location.pathname;
        const plane_id = activePlane?.id
        const searchIndex = basePath.indexOf('/map/');
        if (searchIndex >= 0) {
            basePath = basePath.substr(0, searchIndex);
        }
        const newPath = `${basePath}/map/${plane_id}`;
        window.history.replaceState(null, "", newPath)
        }
        //console.log(activePlane)
        if(activePlane == undefined || id == undefined) {
            setActivePlane(0)
        }
    }, [activePlane]);
    useEffect(() => {
        setRestrictedMode(!props.hasPassport)
        //console.log('has passport ', props.hasPassport)
    }, [props.hasPassport])
    useEffect(() => {
    })
    //console.log(playerRef)
    useEffect(() => {
        if(borderAlert){
            setMintPage(false)
        }
    }, [borderAlert])
    useEffect(() => {
        //console.log('has passport? ', props.hasPassport)
        if(props.hasPassport){
            var color = TokenInfo[props?.passPortId]?.column_5;
            //console.log("pass:", props.passPortId)
            //console.log("color", color)
            socket.emit('setEth', {id: `${socket.id}`,socketID: socket.id, ethAdr: props.ethAdr, color: color})
        } else {
            //socket.emit('setEth', {id: `${socket.id}`,socketID: socket.id, ethAdr: props.ethAdr})
        }
    }, [props.ethAdr, props.hasPassport, restrictedMode])
    useEffect(() => {
        if(zoomToItem && array.length > 0){
            //console.log(array)
            let i = parseInt(id);
            //console.log('id loc', i)
            //console.log('id loc obj', array[i])
            let obj = array[i];
            //console.log(obj)
            //set({ position: [obj?.x, obj?.y, 0.8]})
            playerRef.current?.position.set(new THREE.Vector3(obj?.x, obj?.y, playerRef.current.position.z));
            setPlayerPosX({n: obj?.x, init: obj?.x, t: 'hard'})
            setPlayerPosY({n: obj?.y, init: obj?.y, t: 'hard'})
            //playerRef.current.position.set(obj?.x)
            setActivePlane(array[i]);
            //console.log(playerRef.current?.position.x)
            //setTimeout(() => {
            
            socket.emit('newCoord', {posX: obj?.x, posY: obj?.y, id: `${socket.id}`,socketID: socket.id,msg: props.latestUserMessage});
            setZoomed(true);
            //}, 5000)
            //props.setActivePlaneParent(props.array[i])
        }
        if(!zoomToItem) {
            setZoomed(true);
        }
    }, [zoomToItem, array]);
    useEffect(() => {
        var arr = [];
        var x = 0;
        var y = -1;
        for(var i = 0; i < imageList.length; i++){
            if(i % 11 === 0) {
                y++;
                x = 0;
            }
            arr.push({
                "x": 10 * x,
                "y": 10 * y,
                "img": imageList[i],
                "id": i
            });
            x++;

        }
        //console.log(arr)
        setArray(arr);
    }, []);

    //Loading screen
    const [shown, setShown] = useState(true)
//    console.log('is active', active, progress, loaded, total)
    useEffect(() => {
        if (active !== shown) {
            //setShown(props.active)
        }
        //return () => clearTimeout(t);
    }, [shown, props.active])
    useEffect(() => {
        props.setIsBack(true)
    }, [])
    return(
        <>
        <div className="single">
            {activePlane ?
            <>
            <div className={"info_bar"} onClick={() => setDetailIsHidden(!detailIsHidden)}>
                ML{activePlane?.id + 6}<tokenName>: {TokenInfo[activePlane?.id]?.column_3}</tokenName>
            </div>
            <div className={detailIsHidden ? "info_div hidden" : "info_div" }>
                <img className={"painting_img"} src={require('./Imgs/lowres/'+String(Number(activePlane?.id)+6)+'.jpg')} />
                <div className={"item"}>
                    <div className="title">{props.isEN ? `${TokenInfo[activePlane?.id]?.column_2} Land Token Owned by:` : '去中心國土地代幣，持有者：'}</div>
                    <div className="adr"><a href={'https://etherscan.io/address/'+og_owners[Number(activePlane?.id)]}>{og_owners[Number(activePlane?.id)]}</a></div>
                </div>
                <div className={"item"}>
                    <div className="title">{props.isEN ? "Landscape Painting of token Owned by:" : '景觀畫代幣，持有者：'}</div>
                    <div className="adr"><a href={'https://etherscan.io/address/'+ml_owners[Number(activePlane?.id)]}>{ml_owners[Number(activePlane?.id)]}</a></div>
                </div>
                <div className={"item links"}>
                    <a target="_blank" href={"https://etherscan.io/token/0x42ced6954870f4a17f3a4658d06f63c79de6f7ea?a="+TokenInfo[activePlane?.id]?.column_4}><img src={Etherscan} className={"icon"} /></a>
                    <a target="_blank" href={TokenInfo[activePlane?.id]?.column_1} ><img src={OS} className={"icon"} /></a>
                </div>
            </div>
            </>
            : null}
            {borderAlert ?
                <div className="border_alert">
                    <div className='inner'>
                        {props.isEN ?
                            'Error! You are not allowed to cross borders without a passport. Buy one here.'
                            :
                            '錯誤！你未持有護照，無法過境。請在此購買護照。'
                        }
                    </div>
                    <div className="button" onClick={() => {setBorderAlert(false); setMintPage(true)}}>
                        {props.isEN ?
                            'BUY A PASSPORT'
                            :
                            '購買護照'
                        }
                    </div>
                    <div className="button" onClick={() => setBorderAlert(false)}>
                        OK
                    </div>
                </div>
            : null}
            <Chat isEN={props.isEN} TokenInfo={TokenInfo} socket={socket} setMintPage={setMintPage} hasPassport={props.hasPassport} ethAdr={props.ethAdr} activePlane={activePlane} setLatestUserMessage={setLatestUserMessage} />
            <Canvas {...swipe}  shadows onCreated={(gl) => [gl.toneMapping = THREE.ACESFilmicToneMapping, gl.powerPreference = "high-performance"] }>
                <Suspense fallback={null}>
                <color attach="background" args={["black"]} />
                <CanvasElements setShown={setShown} shown={shown} zoomedToItem={zoomedToItem} array={array} playerPosX={playerPosX} playerPosY={playerPosY} ref={playerRef} passPortId={props.passPortId} latestUserMessage={latestUserMessage} setBorderAlert={setBorderAlert} hasPassport={props.hasPassport} restrictedMode={restrictedMode} zoomToItem={zoomToItem} setActivePlane={setActivePlane} activePlane={activePlane} />
                </Suspense>
                <Environment preset="forest" castShadow />
                <Preload all />
            </Canvas>
            {mintPage ?
            <MintPage activePlane={activePlane} setMintPage={setMintPage} isEN={props.isEN} />
            : 
            null
            }
            </div>            
            <Loader shown={shown} />
            </>

    )
}
export default Map;
