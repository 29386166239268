import * as React from "react";

import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
  Route
} from "react-router-dom";
import Grid from './Grid';
import Map from './Map';
import {useState, useEffect} from 'react';
import { isAddress } from 'web3-validator';
import { ENSName } from 'react-ens-name';
import { withOneTabEnforcer } from "react-one-tab-enforcer"
import TabError from './TabError.js';
import {
  connectWallet,
  CheckWalletBalance
} from "./Hooks/interact.js";
import InfoPage from './InfoPage.js';
import { useLocation } from "react-router-dom";
import GlobalButtons from './GlobalButtons.js';
import useIsBackNavigation from "./Hooks/useIsBackNavigation.js";
const { ethers } = require("ethers")
const images = require.context('./Imgs', true);
const imageList = images.keys().map(image => images(image));
function Alchemy(props) {
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  };
  useEffect(() => {
    addWalletListener();
    //connectWalletPressed()
  }, []);
  useEffect(() => {
    if(window.ethereum) {
      checkBalances()
    }
  })
  useEffect(() => {
    props.setPassportParent(props.passport)
  }, [props.passport])
  const checkBalances = async() =>{
      if(isAddress(walletAddress)) {
      props.setParentEth(walletAddress)
      const c = await CheckWalletBalance(walletAddress);
      console.log(c)
      if(c.passport == true) {
        props.setPassport(true);
        props.setPassportId(c.passportId);
        props.setPassportIdParent(c.passportId)
      }
      }
  }
  async function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", async(accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          checkBalances();
        } else {
          setWallet("");
          checkBalances();
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }
  return(
    <>
    <div className={"metamask_button"} onClick={() => connectWalletPressed()}>
        <div className='adr'>
        {props.passport &&
          <img src={require('./Imgs/passports/'+props.passportId+'.jpg')} />
        }
        {walletAddress.length > 0 ? (
          <ENSName address={String(walletAddress)}></ENSName>
        ) : (
          <span>{props.isEN ? "Connect Wallet" : "連結錢包"}</span>
        )}
        </div>
    </div>
</>
  )
}
function App() {
  const [hasPassport, setPassportParent] = useState(false)
  const [passPortId, setPassportIdParent] = useState(null)
  const [ethAdr, setParentEth] = useState('')
  const [passport, setPassport] = useState(false);
  const [passportId, setPassportId] = useState(null);
  const [isBack, setIsBack] = useState(false);
  const [isEN, setEN] = useState(true);
  
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
      <Route path="/" element={<><Grid isBack={isBack} isEN={isEN} /><GlobalButtons isEN={isEN} setEN={setEN} /></>} />
      <Route path="/map" element={<><Map setIsBack={setIsBack} isEN={isEN} hasPassport={hasPassport} ethAdr={ethAdr} passPortId={passPortId} /><GlobalButtons setIsBack={setIsBack} isEN={isEN} setEN={setEN} /></>} />
      <Route path="/map/:id" element={<><Map setIsBack={setIsBack} isEN={isEN} zoomToItem={true} hasPassport={hasPassport} ethAdr={ethAdr} passPortId={passPortId} /><GlobalButtons setIsBack={setIsBack} isEN={isEN} setEN={setEN} /></>} />
      <Route path="/info" element={<><InfoPage setIsBack={setIsBack} isEN={isEN} /><GlobalButtons setIsBack={setIsBack} isEN={isEN} setEN={setEN} /></>} />
      </>
    )
  );
  useEffect(() => {
    if(!isEN){
      document.body.classList.add('cn');
    } else if (isEN) {
      document.body.classList.remove('cn');
    }
  }, [isEN])
  return (
    <div className="App">
      <React.StrictMode>
      <RouterProvider router={router} />
      <Alchemy isEN={isEN} setPassport={setPassport} passport={passport} passportId={passportId} setPassportId={setPassportId} setPassportParent={setPassportParent} setPassportIdParent={setPassportIdParent} setParentEth={setParentEth} />
      </React.StrictMode>
    </div>
  );
}

export default withOneTabEnforcer({appName: "Metaverse Landscapes: Patchwork", OnlyOneTabComponent: TabError})(App)
