import {useState, useEffect} from 'react';
import { TypeAnimation } from 'react-type-animation';

import Draggable from 'react-draggable'
import Typewriter from 'typewriter-effect';
import { NavLink } from 'react-router-dom';
function GridPopup(props){
    
    const textWithLinks = '"Metaverse Landscapes: Patchwork" is a metaverse of permissionless copies of digital territories.<br /><br />It stitches together depictions of land parcels from various metaverses active in the early 2020s – <a href="https://decentraland.org/">Decentraland</a>, <a href="https://www.sandbox.game/en/">The Sandbox</a>, <a href="https://www.lootproject.com/">Loot Realms</a>, <a href="https://www.voxels.com/">Voxels</a>, <a href="https://somniumspace.com/">Somnium Space</a> and <a href="https://otherside.xyz/">Otherside</a>. <br /><br />Guests can visit parcels in the Patchwork by clicking on a pixel icon on this landing screen. After entering a land parcel, guests can chat with the territory\'s resident – the sovereign native host chatbot – and other users present in the same territory. Buying a passport (by minting a free passport token) allows users to cross borders into other territories and move freely around the Patchwork.';
    const textCN = '「元宇宙景觀：拼貼圖」是由幾個無需權限即可進入的數位領土所組成的元宇宙。它把2020年代初期幾個活躍的元宇宙—<a href="https://decentraland.org/">Decentraland</a>、<a href="https://www.sandbox.game/en/">The Sandbox</a>、<a href="https://www.lootproject.com/">Loot Realms</a>、<a href="https://www.voxels.com/">Voxels</a>、<a href="https://somniumspace.com/">Somnium Space</a>、和<a href="https://otherside.xyz/">Otherside</a>—所描述的景觀拼接在一起。訪客可以在登入畫面上點擊素像圖示，造訪拼貼圖中的不同地號。進入其中一個地號之後，訪客可以和該領地裡的居民——擁有主權的原生聊天機器人——以及其他在同領地出現的其他使用者一起聊天。使用者可購買護照（鑄造免費的通行代幣）以穿越邊境進入其他的領土，在拼貼圖的各處自由活動。'
    useEffect(() => {
        if(props.isEN) {
         setGreeting(textWithLinks)
        }
        else {
            setGreeting(textCN)
        }
    }, [props.isEN]);
    
    const [greeting, setGreeting] = useState(textWithLinks);
    
    const renderTypewriter = () => {
        return (
            <Typewriter
            options={{
                autoStart: true,
                loop: false,
                delay: 15
              }}
                onInit={(typewriter) => {
                    typewriter
                        .typeString(greeting)
                        .start()
                        .callFunction(() => {
                            
                        });
                }}
            />
        );
    };
    
    const [typewriter, setTypewriter] = useState(renderTypewriter());
    
    useEffect(() => {
        setTypewriter(<></>);
        setTimeout(() => setTypewriter(renderTypewriter()));
    }, [greeting]);
    return(
        <>
        {props.isVisible && 
            <div className="grid_popup">
                <div className='inner'>
                {typewriter}
                </div>
                <NavLink to={"/info"}><div className='button'>{props.isEN ? 'LEARN MORE' : '了解更多'}</div></NavLink>
                <div className='button' onClick={() => props.setVisible(false)}>OK</div>
            </div>
        }
        </>
    )
}
export default GridPopup;