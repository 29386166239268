
const short_titles = [
  'Metaverse Landscape 6',
  'Metaverse Landscape 7',
  'Metaverse Landscape 8',
  'Metaverse Landscape 9',
  'Metaverse Landscape 10',
  'Metaverse Landscape 11',
  'Metaverse Landscape 12',
  'Metaverse Landscape 13',
  'Metaverse Landscape 14',
  'Metaverse Landscape 15',
  'Metaverse Landscape 16',
  'Metaverse Landscape 17',
  'Metaverse Landscape 18',
  'Metaverse Landscape 19',
  'Metaverse Landscape 20',
  'Metaverse Landscape 21',
  'Metaverse Landscape 22',
  'Metaverse Landscape 23',
  'Metaverse Landscape 24',
  'Metaverse Landscape 25',
  'Metaverse Landscape 26',
  'Metaverse Landscape 27',
  'Metaverse Landscape 28',
  'Metaverse Landscape 29',
  'Metaverse Landscape 30',
  'Metaverse Landscape 31',
  'Metaverse Landscape 32',
  'Metaverse Landscape 33',
  'Metaverse Landscape 34',
  'Metaverse Landscape 35',
  'Metaverse Landscape 36',
  'Metaverse Landscape 37',
  'Metaverse Landscape 38',
  'Metaverse Landscape 39',
  'Metaverse Landscape 40',
  'Metaverse Landscape 41',
  'Metaverse Landscape 42',
  'Metaverse Landscape 43',
  'Metaverse Landscape 44',
  'Metaverse Landscape 45',
  'Metaverse Landscape 46',
  'Metaverse Landscape 47',
  'Metaverse Landscape 48',
  'Metaverse Landscape 49'
]
const titles = [
    'Metaverse Landscape Title Deed 6: The Sandbox Land (-187, 128)',
    'Metaverse Landscape Title Deed 7: Somnium Space Small #138 (s) 2023',
    'Metaverse Landscape Title Deed 8: The Sandbox Land (-196, -23)',
    'Metaverse Landscape Title Deed 9: Decentraland Parcel -15,-114',
    'Metaverse Landscape Title Deed 10: Voxels 23 Clarion Alley',
    'Metaverse Landscape Title Deed 11: Voxels 2 Balmy Alley',
    'Metaverse Landscape Title Deed 12: Voxels 11 The Embarcadero',
    'Metaverse Landscape Title Deed 13: Voxels 3 Sumner Street',
    'Metaverse Landscape Title Deed 14: Otherdeed for Otherside #50769',
    'Metaverse Landscape Title Deed 15: Voxels 5 Haight Street',
    'Metaverse Landscape Title Deed 16: Decentraland Parcel -102,-63',
    'Metaverse Landscape Title Deed 17: Decentraland Parcel 30,-139',
    'Metaverse Landscape Title Deed 18: Decentraland Parcel -126,-78',
    'Metaverse Landscape Title Deed 19: Decentraland Parcel 6,-142',
    'Metaverse Landscape Title Deed 20: Somnium Space Extra Large #3233 (XL)',
    'Metaverse Landscape Title Deed 21: The Sandbox Land (-2, -23)',
    'Metaverse Landscape Title Deed 22: The Sandbox Land (-71, -159)',
    'Metaverse Landscape Title Deed 23: The Sandbox Land (-121, 155)',
    'Metaverse Landscape Title Deed 24: The Sandbox Land (-173, -12)',
    'Metaverse Landscape Title Deed 25: The Sandbox Land (28, -113)',
    'Metaverse Landscape Title Deed 26: The Sandbox Land (-179, 37)',
    'Metaverse Landscape Title Deed 27: Somnium Space Extra Large #3625 (XL)',
    'Metaverse Landscape Title Deed 28: Decentraland Parcel -26,-49',
    'Metaverse Landscape Title Deed 29: Otherdeed for Otherside #56810',
    'Metaverse Landscape Title Deed 30: The Sandbox Land (148, -2)',
    'Metaverse Landscape Title Deed 31: The Sandbox Land (180, 133)',
    'Metaverse Landscape Title Deed 32: Decentraland Parcel -30,-108',
    'Metaverse Landscape Title Deed 33: Somnium Space Extra Large #2644 (XL)',
    'Metaverse Landscape Title Deed 34: The Sandbox LAND (-108, -158)',
    'Metaverse Landscape Title Deed 35: The Sandbox LAND (-26, -76)',
    'Metaverse Landscape Title Deed 36: The Sandbox LAND (-100, 161)',
    'Metaverse Landscape Title Deed 37: The Sandbox LAND (-135, -34)',
    'Metaverse Landscape Title Deed 38: The Sandbox LAND (77, 162)',
    'Metaverse Landscape Title Deed 39: The Sandbox LAND (87, 192)',
    'Metaverse Landscape Title Deed 40: The Sandbox LAND (156, -119)',
    'Metaverse Landscape Title Deed 41: The Sandbox LAND (-1, -36)',
    'Metaverse Landscape Title Deed 42: Decentraland Parcel -120,-60',
    'Metaverse Landscape Title Deed 43: Decentraland Parcel -107,-64',
    'Metaverse Landscape Title Deed 44: The Sandbox LAND (-179, -50)',
    'Metaverse Landscape Title Deed 45: Anronem - Realms (for Adventurers)',
    'Metaverse Landscape Title Deed 46: Amansau - Realms (for Adventurers)',
    'Metaverse Landscape Title Deed 47: Ajajasupak - Realms (for Adventurers)',
    'Metaverse Landscape Title Deed 48: Amesesh - Realms (for Adventurers)',
    'Metaverse Landscape Title Deed 49: Decentraland Parcel 38,-18'
]
export const og_owners = [
    "0x4173b99F1B61A04B21470054CcECb7b0A7ACA66b",
    "0x850AFE0263DAD595D4AEBB3928dbeE1643D1bff7",
    "0xbFa1beEFa79F73E44b91BA4412F0d6945dbE30a8",
    "0x84dE36e80D9A4BC5Da6099D6B85D8dA5844cd4ae",
    "0x47144372eb383466D18FC91DB9Cd0396Aa6c87A4",
    "0xe45227805a729302535Adf5d456fcE6768B35d27",
    "0x5Fae9D4B591f213b3bA75287f2cfAc0883D17f7A",
    "0xd0c2c8c2d41091f0d4888c9303C70C48b15fB945",
    "0x4612F6cCDEece14255a757395477907D5f33038c",
    "0xc8AB6267bC5c470CdfBeefc0517F26eeF45B68b1",
    "0x21C6c4d7a2cB4F04929B5B7500868D5Db370889B",
    "0x3a1469499d0be105D4f77045Ca403A5f6dC2F3f5",
    "0x6f91B5941Fd320c8F03D33811E52D8de0B29bc86",
    "0x4A5B5fD2E4DbE463352aE6412aA648F6942fEE07",
    "0x8A7334579a100c5Bb4cf17E805B0a8799760F265",
    "0xAE11A1122E04B97908EDF2Ab17218Ca7A6a5f6DD",
    "0x6cE82874EAf6E7602fD21Cf8bBDEd82705680A99",
    "0x7a22A75519DCc47551e472fcA502E573f0aF790c",
    "0x5DFcc099CA8a00E2355bB028A1CD448715D282c5",
    "0x290bc2F9C4Ff270415dc0Fb0B2A2D9D7bFe4c828",
    "0xb72Ec698c98d36e2eeA8F584fA294C37a5AB9f73",
    "0x53dab28a1064f826Fbd1B31650FB067BE76F61eF",
    "0x959e104E1a4dB6317fA58F8295F586e1A978c297",
    "0x000000000000000000000000000000000000dEaD",
    "0x6cE82874EAf6E7602fD21Cf8bBDEd82705680A99",
    "0x4BDC3308d002Dd88C833496b2a2E06414fffcDbf",
    "0x81AaA3894D5f9793A6aAa49E5BBdd68C25224CF5",
    "0xb98CDaCd006B9d47C37Ca63Cc86F916EE23Fc550",
    "0xd03d26b36642c8137c77AE8fe91E205252db1095",
    "0xCab6614871c493909491ad8b0aeDd304c5cF4c3d",
    "0x5Ae579a61EC7244E58B43B77D45c311be2c3275f",
    "0xb7b50e3Ad940296cF8EC676A304d627721B539CD",
    "0x16cc6066FAd27019a5CB09E29618610e678F1293",
    "0xf5951700326259D322E350F450c22E01B11f0EB7",
    "0x6cE82874EAf6E7602fD21Cf8bBDEd82705680A99",
    "0x66EeAF5e14183Ba008044624B0f4ac9519eF546B",
    "0xa0DcF08C9531F130132149EdE19aF4639C021089",
    "0x8eC697e72b245c7Ef73C22E2506a314C665B23C7",
    "0x447a3C9BCD0B494F4EE7F542Aa1660a8A478AFe9",
    "0x17963290db8C30552D0cfa2A6453FF20a28C31a2",
    "0x17963290db8C30552D0cfa2A6453FF20a28C31a2",
    "0x17963290db8C30552D0cfa2A6453FF20a28C31a2",
    "0x17963290db8C30552D0cfa2A6453FF20a28C31a2",
    "0xf47a1792C26B4D1e88778120d47029E14Dd51CEd"
  ];
export const ml_owners = [
    "0x0DF560324e57A749938FF1D7552ca18e7106AeeB",
    "0xb28D6f4faf4C82A639aBf42fBEaC021aff137b66",
    "0x46f49071a327b1F19CceE2d2DF27b3eBCF168240",
    "0xa823C55dA39787F99E8F9455205cD170f45490b7",
    "0xc14Dc30C059489F5F82FEb69b6f23dcdDFde13e3",
    "0xb5F8158310a9D34Bf3aF78061CECa2738c378208",
    "0x212Ac7FDacb7de408C158bE3641E2ADa0ae88090",
    "0x92afAbD2Cb4A9a64837631718CD9108c53d065F9",
    "0xC5e728aA64D4571F18cd8D889fC688E4f8a550Dd",
    "0x75f40672b56E3B5417F497E0F523831b2317356a",
    "0x4C03CDa57013dB5E83b8E86812446A86092AD4d2",
    "0xBc425a92Fd438F9535023913F456404Fb5eB5589",
    "0xFDe35C6AbeC6dc1Dd7b69098eC43Dab81c8B551a",
    "0x5584F7097d6Ac94f20F15656478550A43Aa8bea3",
    "0x94966BC75dc8a65B0e86D9B98F901A8c2fb9F2BC",
    "0x7f7E1eF9CC3C9623e82538a7b3781BC47bd073aa",
    "0x72110C64BA6dD85129564aF2d8e69E62DB2f4015",
    "0x35581369F537D02A3e5B83247f9Bf29f94851EBf",
    "0x54840E5EeFC8e628a2f5893bdb968caD1043fA3B",
    "0x5e2050E6f5B5F220517fE3826076761d0414450b",
    "0xEcbEac980CF6CDb4FDB5C9f3e609597E4FF03fD4",
    "0xbBE9bEfDF113a77170e0128037AbC928dC70ef94",
    "0xEC7478B30434D0Da0f5073a130A562150EEc8a3B",
    "0x29A6b3Afd44729728b48a02Bd3fADD84664Bc91B",
    "0xDB58A83A9236fDfA4b7711033dbCA033b781027c",
    "0xE510B8acDB8736a4d717e49c2932b667A30af5dc",
    "0x457D4A4e67AcE9baC844DdCe0e381FD119581A94",
    "0xcd9965F0A3054fBb45d1e7C841a850cDcA39E4FD",
    "0xBFc7f542F141Dc8CFcc92BdE447A8E33aB56150d",
    "0xC1Af33233DbEd48ab1922bfF12d86cB93F62948A",
    "0xF9fCd9D345BEFbe17FD99191B353786c0378f3bf",
    "0x97e9Ba39d61ee41C27BC5778B7e807fFcDb3A0dD",
    "0x40432E5ed6ED1141A719565861FDfC559D6A15c4",
    "0x27950995E10BFA988a4049e82574F6dB8a49b915",
    "0x58cFCfDc44677fA316929E7141f6A64bAc9dda8e",
    "0x3e4e9127a0CE578eC366dC5d58aCfa8eDc6Add42",
    "0x7bf4D4fD5f09CEFc90CFCC545a5C67815B34F817",
    "0xd6C1698eAB27d9dCB646081174C76763b850504A",
    "0x6305B1DB75f7Eaf3dc82D99EC26C8Aa3f746D3BD",
    "0xbBD68150A81bD4385cd5176fEA2f19CBa6CbDa01",
    "0xc036b0032200410824B7962a3B1D664B50c45120",
    "0x0d714992DBC3597E243590ba653D0CEFfBa2039D",
    "0x3de5Beea3709902273714cFDf2DaEe0e732073e2",
    "0xAa9E4845f933d1d3d30925135BA151e1A1a41c1f"
  ]