import logo from './logo.svg';
import './App.css';
import { Pixelify } from "react-pixelify";
import table from './data.json';
import {
  createBrowserRouter,
  RouterProvider,
  NavLink
} from "react-router-dom";
import GridPopup from './GridPopup';
import useMousePosition from './Hooks/useMousePosition.js'
import { useEffect, useState, useRef } from 'react';
import { useWindowSize } from './Hooks/useWindowSize.js';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLocation } from 'react-router-dom';
import exportAsImage from './Hooks/exportAsImage.js';
import GridPopupLogo from './GridPopupLogo.js';
const images = require.context('./Imgs/lowres/pixel', true);
const imageList = images.keys().map(image => images(image)).sort((a, b) => {
  const aNum = Number(a.split('.')[0]);
  const bNum = Number(b.split('.')[0]);
  if (aNum < bNum) return -1;
  if (aNum > bNum) return 1;
  return 0;
});
console.log(imageList)

function Grid(props) {
  const isMobile = useMediaQuery('(max-width:700px)');
  const { x,y } = useMousePosition();
  const [viewPort, setViewport] = useState({});
  const [gallerySize, setGallerySize] = useState({});
  const [galleryScale, setGalleryScale] = useState(null);
  const [percentX, setPercentX] = useState(0);
  const [pos, setPos] = useState({})
  const [parcel, setParcel] = useState(null)
  const [isVisible, setVisible] = useState(true)
  const [gridIsVisible, setVisibleGrid] = useState(false)
  const [showAdditionalComponent, setShowAdditionalComponent] = useState(false);
  const GalleryRef = useRef(null)
  const GalleryOuterRef = useRef(null)
  const windowSize = useWindowSize()
  let location = useLocation();

  const styles = { 
        transform: `translate(${pos.x}, -50%) scale(${galleryScale})`,
        opacity: gridIsVisible ? 1 : 0
  };
  const xBool = percentX > 0.5 ? -80 : 10;
  const textboxstyle = {
        position: 'fixed',
        left: x + xBool,
        top: y + 10,
        color: "white",
        'pointer-events': 'none'
  }
  useEffect(() => {
    setViewport({'height': window.innerHeight, 'width': window.innerWidth})
    setGallerySize({'height': GalleryRef.current.offsetHeight, 'width': GalleryRef.current.offsetWidth})
    const scale = window.innerHeight / GalleryRef.current.offsetHeight;
    setGalleryScale(scale)
    //console.log('called on start', scale)
  }, [location, GalleryRef.current?.offsetHeight, window.innerHeight, window.innerWidth, GalleryOuterRef.current?.offsetHeight])
  useEffect(() => {
    const handleResize = () => {
      setViewport({'height': window.innerHeight, 'width': window.innerWidth})
    };
    //exportAsImage(GalleryRef.current, "screen.png")
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  /*useEffect(() => {
    setGallerySize({'height': GalleryRef.current.offsetHeight, 'width': GalleryRef.current.offsetWidth})
  }, [viewPort])*/
  useEffect(() => {
    if(gallerySize.width && gallerySize.height) {
      //setPos({x: '-50%', y: 0})
      setVisibleGrid(true)
    }
  }, [gallerySize])
  useEffect(() => {
  }, [viewPort, gallerySize])
  useEffect(() => {
    if(props.isBack) {
      setVisible(false)
    }
  }, [props.isBack])
  useEffect(() => {
    const bounds = GalleryOuterRef.current.getBoundingClientRect();
    const bx = x - bounds.left;
    const by = y - bounds.top;
    const percentX = bx / bounds.width;
    const percentY = by / bounds.height;
    const maxMoveX = (GalleryRef.current.clientWidth * galleryScale) - bounds.width;
    const maxMoveY = bounds.height - GalleryRef.current.clientHeight;
    const moveX = maxMoveX * percentX;
    const moveY = maxMoveY * percentY;
    //console.log(bounds.width, GalleryRef.current.offsetWidth)
    setPos({x: -moveX+'px', y: y/10})
    setPercentX(percentX);
    //console.log(moveX, maxMoveX, percentX)
    //console.log(x)
  }, [x, y])
    return(
      <>
      <div className='gallery_outer' ref={GalleryOuterRef}>
        <div className="gallery" ref={GalleryRef} style={isMobile ? null : styles}>
        {table.table.map((image, index) => (
        <div className="item" onMouseOver={() => setParcel(String(index+6))}>
          {isVisible ?  <img src={require('./Imgs/lowres/pixel/'+image.imgFile)} /> :
            <NavLink to={"/map/"+index}>
            <img src={require('./Imgs/lowres/pixel/'+image.imgFile)} />
            </NavLink>
          }
        </div>
      ))}
      </div>
      </div>
      {!isVisible && parcel != null &&  
        <div className='gallery_textbox' style={textboxstyle}>
          ML #{parcel}
        </div>
      }
      {props.isBack ? <GridPopupLogo /> :
      <GridPopup isEN={props.isEN} isVisible={isVisible} setVisible={setVisible} />
      }
      </>
    )
}
export default Grid;
