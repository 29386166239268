import {
    mintNFT
} from "./Hooks/interact.js";
import { useState,useEffect } from 'react';
import { CheckParcelPassports } from './Hooks/interact.js';
function MintPage(props) {
    const [isMinted, setMinted] = useState(false)
    const [status, setStatus] = useState("disclaimer")
    const [isError, setError] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [noPassports, setNoPassports] = useState(false)
    const [hash, setHash] = useState(null)
    const [isEth, setEth] = useState(false)
    const [dots, setDots] = useState('')
    const chain = 1;
    const chainHex = "0x1";
    function dotString() {
        return "." * dots
    }
    useEffect(() => {
        if(window.ethereum.networkVersion == chain) {
            setEth(true)
        } else {
            switchChain()
        }
    })
    useEffect(() => {
        setTimeout(() => {
                if(dots.length >= 3) {
                    setDots('')
                } else {
                    setDots(dots+'.')
                }
        }, 500);
    }, [dots])
    useEffect(() => {
        checkAvailability()
    }, [])
    async function checkAvailability() {
        const p = await CheckParcelPassports(props.activePlane.id);
        if (p.passportsAvailable == false) {
            setNoPassports(true)
        }
        //console.log(p)
    }
    async function switchChain() {
        if(window.ethereum){
            try {
                const accounts = await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: '0x1' }],
                });
                setEth(true)
            } catch (error) {
            }
        }
    }
    async function mintButton(){
        if(window.ethereum.networkVersion == chain) {
            setStatus("loading")
            const mint = await mintNFT(String(props.activePlane.id));
            console.log(mint)
            if(mint.success == true) {
                setStatus("minted")
                setHash(mint.hash)
            } else if (mint.success == false) {
                setError(true)
            }
        } else {
            switchChain()
            console.log("switch chain")
        }
    }
    return(
        <div className='mint_popup'>
            {props.activePlane && status != "disclaimer" &&
                    <>
                    <img src={require('./Imgs/passports/'+props.activePlane.id+'.jpg')}></img>
                    <br />
                    </>
            }
            {noPassports &&
            <>
                {props.isEN ? 'No passports left for this parcel, try another one!\n\n' : '本地號已無護照，請嘗試其他地號！\n\n'}
            </>
            }
            {status == "disclaimer" && !noPassports &&
            <>
                    <div className="disclaimer">
                    {props.isEN ? 
                    "Please note: no transactions are made on this website. To mint a passport token, this is done through the Ethereum blockchain using Metamask. All transactions on Metamask are fully independent of TFAM, the artist and this website. TFAM and the artists are not responsible for any transactions." 
                    : 
                    "請注意：本作品網站不含任何交易功能。鑄造護照為連結虛擬貨幣錢包Metamask，透過以太幣區塊鏈進行交易。所有在Metamask上的交易皆完全獨立、不經過臺北市立美術館、藝術家、此作品網站。故臺北市立美術館、藝術家概不負相關交易責任。"
                    }
                    </div>
                    <div className="button" onClick={() => setStatus("mint")}>
                        OK
                    </div>
            </>
            }
            {status == "mint" && !noPassports &&
            <>
                    0 ETH<br />
                    {isEth ?
                    <div className="button" onClick={() => mintButton()}>
                        {props.isEN ? "Mint Passport" : "鑄造護照"}
                    </div>
                    :
                    <div className="button" onClick={() => switchChain()}>
                        {props.isEN ? "Connect to Eth" : "連結至以太幣"}

                    </div>
                    }
            </>
            }
            {status == "minted" &&
             <>
             {props.isEN ? "Your passport has been minted!" : "你的護照已鑄造完成！"}
             <br />
             <a href={"https://etherscan.io/tx/" + hash}>
             <div className="button">
            {props.isEN ? "View Transaction" : "查看交易"}
             </div>
             </a>
             </>
             }
            {status == "loading" &&
                <>
                0 ETH<br />
                <div className="button gray">
                    {props.isEN ? "Minting" : "鑄造中"}{dots}
                </div>
                </>
            }
            <div className="close_icon" onClick={() => props.setMintPage(false)}>
                X
            </div>
        </div>
    )
}
export default MintPage;