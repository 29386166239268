import React, { useState, useEffect, useRef } from 'react';
//import Pixel from './Fonts/PixeloidMono_Regular.json'
import { useSpring, animated } from 'react-spring';
import { Text } from "troika-three-text";
import { Canvas, useFrame, useLoader, extend} from '@react-three/fiber'
import PixeloidMono_Regular from './Fonts/PixeloidMono.woff'

extend({ Text });

const FadingText = ({ text1, position, duration = 5000 }) => {
  const [visible, setVisible] = useState(true);
  const previousTextRef = useRef(null);
  //const font = useFont(Pixel);
  const { opacity, y } = useSpring({
    opacity: visible ? 1 : 0,
    y: visible ? 0 : -1,
    config: { duration },
  });
  useEffect(() => {
    if (previousTextRef.current !== text1) {
      setVisible(true);
      previousTextRef.current = text1;

      const timer = setTimeout(() => {
        setVisible(false);
      }, duration);

      return () => clearTimeout(timer);
    }
  }, [text1, duration]);

  if (!visible) return null;

  return (
    <>
    <text
          position={[0, 0.3, 0]}
          font={PixeloidMono_Regular}
          rotation={[0, -0.3, 0]}
          fontSize={0.08}
          text={text1}
          color={'#ff4500'}
          anchorX="center"
          anchorY="middle"
        >
            <meshStandardMaterial emissive={"#ff4500"} attach="material" color={"#ff4500"} />
        </text>
    </>
  );
};

export default FadingText;