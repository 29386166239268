import { useLocation, NavLink } from "react-router-dom"
function GlobalButtons(props) {
    const location = useLocation();
    console.log(location)
    return(
        <div className="global_buttons">
            {location.pathname != "/" &&
    <NavLink to="/" onClick={() => props.setIsBack(true)}>
    <div className='backtomap_button'>
    <div className='adr'>
        <span>
        {props.isEN ? "← Back" : "← 回到地圖"}
        </span>
        </div>
    </div>
    </NavLink>
}   
      <div className="language_button" onClick={() => props.setEN(!props.isEN)}>
        <div className='adr'>
          <span>
        {props.isEN ? "中文" : "EN"}
        </span>
        </div>
      </div>
      </div>
    )
}
export default GlobalButtons;