import { useRef, useCallback } from 'react';

export function useCombinedRefs() {
  const targetRef = useRef();
  const setRefs = useCallback((node) => {
    // Attach node to the ref object from useRef
    targetRef.current = node;

    // You can add more refs here if needed
    // e.g., if another component needs to use this ref
  }, []);

  return [targetRef, setRefs];
}