import {useState, useEffect, useRef} from 'react';
import { BarLoader } from 'react-spinners';
const Hourglass = () => {
    const [sandTop, setSandTop] = useState(0);
    const [sandBottom, setSandBottom] = useState(100);
    const hourglassHeight = 100;
    const sandColor = '#F8D8A0'; // Golden yellow sand color
  
    // useRef hook to store the interval ID for smooth animation
    const intervalRef = useRef(null);
  
    useEffect(() => {
      // Start the animation on mount
      const intervalId = setInterval(() => {
        if (sandTop < sandBottom) {
          setSandTop(sandTop + 1);
          setSandBottom(sandBottom - 1);
        } else {
          clearInterval(intervalId);
        }
      }, 50); // Adjust `50` for desired animation speed
  
      return () => clearInterval(intervalId); // Clear interval on unmount
    }, []);
  
    return (
      <div className="hourglass-container">
        <div
          className="hourglass-top"
          style={{ height: `${sandTop}%`, backgroundColor: sandColor }}
        />
        <div
          className="hourglass-middle"
          style={{ height: '20%', backgroundColor: 'black' }}
        />
        <div
          className="hourglass-bottom"
          style={{ height: `${sandBottom}%`, backgroundColor: sandColor }}
        />
      </div>
    );
  };
  
export function Loader(props) {
    return(
        <>
        <div className={props.shown ? 'loader' : 'loader hidden'}>
            <BarLoader cssOverride={{margin: "0 auto", marginTop: '50vh', border: "1px #fff solid", background: 'transparent', padding: 0, borderRadius: '0 !important'}} color={'white'} />
        </div>
        </>
    )
}