import NFTContract from '../NFT/Contract.json';
import { Alchemy, Network } from 'alchemy-sdk';
const { ethers } = require("ethers");
const alchemyKey = 'EGTHK1CPa9hOWnjG06cNiwPQpyhdF8e9';
const contractABI = NFTContract.abi;
const contractAddress = "0x2e92a4db434ba16911f0654870ada834f32bfd27";
const settings = {
    apiKey: alchemyKey, // Replace with your Alchemy API key.
    network: Network.ETH_MAINNET // Replace with your network.
  };
const alchemy = new Alchemy(settings);
var provider = new ethers.AlchemyProvider('homestead', alchemyKey)
var contract = new ethers.Contract(contractAddress, contractABI, provider);

export const connectWallet = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const obj = {
        status: "👆🏽 Write a message in the text-field above.",
        address: addressArray[0],
      };
      //console.log(obj)
      return obj;
    } catch (err) {
        console.log(err)
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (addressArray.length > 0) {
        return {
          address: addressArray[0],
          status: "👆🏽 Write a message in the text-field above.",
        };
      } else {
        return {
          address: "",
          status: "🦊 Connect to Metamask using the top right button.",
        };
      }
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};
export async function CheckWalletBalance(adr) {
    var balance = await contract.balanceOf(adr);
    //var paintingId = await contract.getTokensByOwner(adr);
    var tokenId = [];
    //const x = await contract.ownerOf(0)
    //console.log('owner of', x)  
    const options = {contractAddresses: [contractAddress]};
    const nftsForOwner = await alchemy.nft.getNftsForOwner(adr, options);
    if(nftsForOwner.ownedNfts[0]) {
      const pId = await contract.getInfo(nftsForOwner.ownedNfts[0].tokenId);
      return {
        passport: true,
        passportId: pId
      };
    } else {
      return {
        passport: false,
      };
    }
    
    /*if (balance == 0n) {
      return {
            passport: false,
      };
      //setPassport(false)
    } else {
        return {
            passport: true,
            passportId: pId
      };
    }*/
}
export async function CheckParcelPassports(tokenId) {
  var passportsMinted = await contract.getPassportsPerParcel(tokenId);
  //console.log(passportsMinted)
  if (passportsMinted >= 3n) {
    return {
          passportsAvailable: false,
    };
    //setPassport(false)
  } else {
      return {
          passportsAvailable: true 
    };
  }
}
export const mintNFT = async (id) => {
  try {
    const provider = new ethers.BrowserProvider(window.ethereum)
    await provider.send('eth_requestAccounts', []);
    const signer = await provider.getSigner();
    const addressArray = await window.ethereum.request({
        method: "eth_accounts",
    });
    const contract = new ethers.Contract(
        contractAddress,
        contractABI,
        signer);
    
    const tx = await contract.mint(addressArray[0], id);
    // Wait for the transaction to be confirmed
    await tx.wait();
    CheckWalletBalance(addressArray[0]);
    return {
      success: true,
      hash: tx.hash
    }
    console.log(tx)
  } catch (error) {
    // Handle errors here
    if (error.code === 4001) {
        // User rejected the transaction
        console.log('Transaction rejected by user.');
    } else {
        console.error('Error:', error);
        // Handle other errors as needed
    }
    return {
      success: false
    }
}
};